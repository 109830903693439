import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Explore Our Services at Avis Rent a Car
			</title>
			<meta name={"description"} content={"Your journey starts here"} />
			<meta property={"og:title"} content={"Our Services | Explore Our Services at Avis Rent a Car"} />
			<meta property={"og:description"} content={"Drive the difference with every trip"} />
			<meta property={"og:image"} content={"https://fun.winterstarol.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fun.winterstarol.com/img/car-rental-icon-png.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 120px 0" md-padding="80px 0 90px 0" quarkly-title="Headline-2">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Text margin="0px 0px 20px 0px" font="normal 700 52px/1.2 --fontFamily-sans" color="--dark" sm-font="normal 700 42px/1.2 --fontFamily-sans">
			Our Service Highlights
			</Text>
			<Text
				margin="0px 180px 80px 0px"
				font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
				color="#333"
				lg-margin="0px 0 50px 0px"
				md-margin="0px 0 50px 0px"
			>
				At Avis Rent a Car, we go beyond just renting cars. We provide a full spectrum of services designed to enhance your travel experience, from the moment you book to when you return the keys. Explore our diverse offerings and find out how we tailor our services to fit every traveler's needs.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--dark" font="normal 500 22px/1.2 --fontFamily-sans">
						Diverse Vehicle Options
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#333">
						Economy to Luxury: Whether you need a budget-friendly compact or a high-end luxury vehicle, our fleet caters to all preferences and budgets.
						<br/><br/>
Specialty Vehicles: For those looking for something extra, like a convertible or a minivan, we’ve got a variety of options to make your trip special.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--dark" font="normal 500 22px/1.2 --fontFamily-sans">
						Flexible Rental Terms
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#333">
						Short and Long-Term Rentals: Whether it's a day trip or a month-long journey, we offer flexible durations to suit your schedule.
						<br/><br/>
Customizable Packages: Tailor your rental with add-ons like GPS navigation, child seats, or additional driver options.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--dark" font="normal 500 22px/1.2 --fontFamily-sans">
						Travel Enhancements
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#333">
						Navigation Tools: Never get lost with our state-of-the-art GPS systems available in all vehicles.
						<br/><br/>
Comfort Add-ons: Enhance your ride with extras like Wi-Fi hotspots, entertainment systems, and more.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--dark" font="normal 500 22px/1.2 --fontFamily-sans">
						Safety First
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#333">
						24/7 Roadside Assistance: Peace of mind comes standard with our around-the-clock roadside support.
						<br/><br/>
Comprehensive Insurance Options: Choose from various coverage levels to protect your journey.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--dark" font="normal 500 22px/1.2 --fontFamily-sans">
						Business Travel Services
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#333">
						Corporate Accounts: Benefit from our customized corporate plans that offer flexible billing and discounted rates.
						<br/><br/>
Meeting & Event Transportation: We manage logistics for groups needing multiple vehicles, ensuring seamless transportation.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--dark" font="normal 500 22px/1.2 --fontFamily-sans">
						Eco-Friendly Initiatives
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#333">
						Hybrid and Electric Models: Reduce your carbon footprint with our range of environmentally friendly vehicles.
						<br/><br/>
Carbon Offset Programs: Participate in our initiatives to help mitigate the environmental impact of your travels.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});